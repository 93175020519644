import { Button } from '@material-ui/core';
import useAuth from 'hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { getAssessmentTest } from 'redux/slices/dialogs';
import { CSSProperties } from 'react';
import useLocales from '../hooks/useLocales';
import { RootState } from '../redux/store';

export const TestAssessmentButton = ({ style }: { style?: CSSProperties }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.dialogs);
  const { user } = useAuth();
  const { t } = useLocales();

  const onTestingStart = () => {
    if (user?.id) {
      dispatch(getAssessmentTest(user?.id));
    }
  };

  return (
    <>
      <Button
        style={{
          background:
            'linear-gradient(92.25deg, #9b5fda -2.57%, #562c82 113.38%)',
          padding: '10px 45px',
          borderRadius: '30px',
          textTransform: 'capitalize',
          color: 'white',
          ...style
        }}
        disabled={loading}
        onClick={(e) => {
          e.preventDefault();
          onTestingStart();
        }}
      >
        {t('Assessment Test Required')}
      </Button>
    </>
  );
};
