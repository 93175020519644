import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, NavLink, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
  Modal,
  Button
} from '@material-ui/core';
import { PATH_DASHBOARD } from 'routes/paths';
import { getTranslatedList } from 'constants/constants';
import { RootState } from 'redux/store';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardSharp';
import IconButton from '@material-ui/core/IconButton';
import { getToDoPostData } from 'redux/slices/toDo';
import LoadingScreen from 'components/LoadingScreen';
import { updateJobPost } from 'requests/vacancy';
import { useSnackbar } from 'notistack';
import useLocales from 'hooks/useLocales';
import { fDateJobPOst } from 'utils/formatTime';
import { getCandidateOverview } from 'requests/candidate';
import { useTranslation } from 'react-i18next';
import ModalToDo from './new-designer/modal-window/modalToDo';
import EmptyImageContent from './new-designer/emptyImageContent';
import useAuth from '../hooks/useAuth';
import MFilter from './new-designer/fillter/fillter';
import MButton from './new-designer/button/MButton';

const MainContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '2vh'
}));

const CardLink = styled(NavLink)(() => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '50px',
  textDecoration: 'none'
}));

export const DashboardDailyApplicants = (): JSX.Element => {
  const [windowWidth, setWindowWidth] = useState<any>({});
  const [showAll, setShowAll] = useState(false);
  const { dailyApplicants, isLoading }: any = useSelector(
    (state: RootState) => state.showcase
  );
  const { isReviewer, isHiringManager } = useAuth();
  const { results }: any = dailyApplicants;

  const downloadCv = async (id: any) => {
    const candidate = await getCandidateOverview(id?.candidate?.id);
    if (candidate?.cv) {
      window.open(candidate?.cv, '_blank');
    }
  };

  const { t } = useLocales();

  const applicantsText = t('Waiting for candidates to apply');

  const getNoApplicantsSection = () => {
    if (isHiringManager || isReviewer) {
      return (
        <Box
          width="100%"
          height="500px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            sx={{
              color: '#562C82',
              fontSize: '24px',
              fontWeight: 700,
              fontFamily: 'Rubik',
              textAlign: 'center'
            }}
          >
            {applicantsText}
          </Typography>
        </Box>
      );
    }
    return (
      <EmptyImageContent
        text={applicantsText}
        href={PATH_DASHBOARD.jobPosts.getJobPostUrl('new')}
        xs={windowWidth?.indexSx > 1590 ? 3 : 2}
      />
    );
  };

  return (
    <>
      {isLoading ? (
        <Box sx={{ px: 24, py: 12 }}>
          <LoadingScreen />
        </Box>
      ) : (
        <MainContainer>
          {results?.length != 0 && (
            <Grid
              container
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="end"
              style={{ marginBottom: '20px', marginRight: '15px' }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '20px',
                  color: '#131417',
                  marginTop: '45px',
                  fontFamily: 'Rubik'
                }}
              >
                {t('New Applicants')}
              </Typography>
              <RouterLink
                to={PATH_DASHBOARD.candidates.applicantsOverview}
                style={{ textDecoration: 'none' }}
              >
                <MButton title={t('Show All >>')} MyClass="blueButton" />
              </RouterLink>
            </Grid>
          )}
          <Grid container>
            {results && results?.length
              ? results.map((item: any, index: number) =>
                  showAll
                    ? index > 0
                    : index < 4 && (
                        <Grid
                          key={item.id}
                          style={{
                            marginRight: '15px',
                            borderRadius: '20px',
                            marginBottom: '19px',
                            width: '100%',
                            height: '100px',
                            background:
                              'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)'
                          }}
                        >
                          <CardContent
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                          >
                            <CardLink
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%'
                              }}
                              to={PATH_DASHBOARD.candidates.getCandidateProfileUrl(
                                item.candidate.id
                              )}
                            >
                              <Avatar
                                src={item.candidate.avatar}
                                sx={{ mr: 2.5, width: '60px', height: '60px' }}
                                alt="avatar"
                              />
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid>
                                  <Link
                                    variant="body2"
                                    sx={{
                                      fontSize: '20px',
                                      fontWeight: 700,
                                      color: '#131417'
                                    }}
                                  >
                                    {item.candidate.name}
                                  </Link>
                                  <Typography
                                    sx={{ color: '#C9CAD9', fontSize: '16px' }}
                                  >
                                    {' '}
                                    {item.vacancy.title}
                                  </Typography>
                                </Grid>
                                <Grid>
                                  <MButton
                                    title="View More >>"
                                    MyClass="blueButton"
                                    style={null}
                                    click={() => setShowAll(!showAll)}
                                  />
                                </Grid>
                              </Grid>
                            </CardLink>
                            <MButton
                              title="Download CV"
                              MyClass="violetButton"
                              style={{ marginLeft: '25px' }}
                              click={() => downloadCv(item)}
                            />
                          </CardContent>
                        </Grid>
                      )
                )
              : getNoApplicantsSection()}
          </Grid>
        </MainContainer>
      )}
    </>
  );
};

export const DashboardOpenJobPosts = () => {
  const { isReviewer, isHiringManager } = useAuth();
  const { openJobPosts, isLoading } = useSelector(
    (state: RootState) => state.showcase
  );
  const { results } = openJobPosts || {};
  const [windowWidth, setWindowWidth] = useState<any>({});
  const { base }: any = useSelector((state: RootState) => state.cms);
  const [allJobs, setAllJobs] = useState<any>();
  const {
    DEFAULT_MESSAGE_TEXTS: { error }
  } = getTranslatedList();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocales();

  const jobTitle = t("Let's start creating your first job post");

  const allJobPinned = (parse: any) =>
    parse?.sort(
      (a: { pinned: any }, b: { pinned: any }) =>
        Number(b.pinned) - Number(a.pinned)
    );

  useEffect(() => {
    setAllJobs(allJobPinned(JSON.parse(JSON.stringify(results || []))));
  }, [results]);

  useEffect(() => {
    setWindowWidth({ indexSx: window.innerWidth });
  }, [results]);

  const setPinnedJob = async (id: number, e: React.MouseEvent) => {
    e?.preventDefault();
    const pinnedJob = allJobs?.find((job: { id: number }) => job.id === id);
    try {
      const result = await updateJobPost(id, {
        pinned: !pinnedJob.pinned
      });
      if (result) {
        const jobs = [...allJobs];
        jobs.splice(jobs.indexOf(pinnedJob), 1, {
          anonymous: result?.anonymous,
          id: result?.id,
          pinned: result?.pinned,
          slug: result?.slug,
          title: result?.title,
          view_count: result?.view_count,
          created_at: result?.created_at,
          customer: result?.customer?.name
        });
        const jobsPin = allJobPinned(jobs);
        setAllJobs(jobsPin);
      } else {
        enqueueSnackbar(error, { variant: 'error' });
      }
    } catch {
      enqueueSnackbar(error, { variant: 'error' });
    }
  };

  const getNoJobPostsSection = () => {
    if (isHiringManager || isReviewer) {
      return (
        <Box
          width="100%"
          height="500px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            sx={{
              color: '#562C82',
              fontSize: '24px',
              fontWeight: 700,
              fontFamily: 'Rubik',
              textAlign: 'center'
            }}
          >
            {t('There are no Job Posts to show')}
          </Typography>
        </Box>
      );
    }
    return (
      <EmptyImageContent
        text={jobTitle}
        href={PATH_DASHBOARD.jobPosts.getJobPostUrl('new')}
        xs={windowWidth?.indexSx > 1590 ? 3 : 2}
      />
    );
  };

  return (
    <>
      {isLoading ? (
        <Box sx={{ px: 24, py: 12 }}>
          <LoadingScreen />
        </Box>
      ) : (
        <MainContainer style={{ display: 'flex' }}>
          <Grid
            container
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '19px'
            }}
          >
            {allJobs?.length !== 0 && !(isReviewer || isHiringManager) && (
              <Grid
                xs={windowWidth?.indexSx > 1590 ? 3 : 2}
                sx={{
                  minHeight: '171px',
                  border: '1px solid white',
                  borderRadius: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  maxWidth: '376px !important',
                  width: '100%',
                  flexBasis: '30%'
                }}
              >
                <Link
                  href={PATH_DASHBOARD.jobPosts.getJobPostUrl('new')}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Grid
                    style={{
                      backgroundImage: `url("/static/img/icons/plus.svg") `,
                      width: '29.19px',
                      height: '29.19px',
                      marginBottom: '16px'
                    }}
                  />
                  <Typography
                    sx={{
                      color: '#562C82',
                      fontSize: '16px',
                      fontWeight: 700,
                      fontFamily: 'Rubik'
                    }}
                  >
                    {t('Add New job post')}
                  </Typography>
                </Link>
              </Grid>
            )}
            {allJobs && allJobs?.length
              ? allJobs?.map(
                  (item: any, index: number) =>
                    index < 6 && (
                      <Grid
                        item
                        xs={windowWidth?.indexSx > 1590 ? 3 : 5}
                        key={item.id}
                        style={{ flexBasis: '376px', maxWidth: '30%' }}
                      >
                        <Card
                          sx={{
                            maxWidth: '378px',
                            width: '100%',
                            background:
                              'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
                            borderRadius: '20px',
                            minHeight: '200px',
                            height: '100%'
                          }}
                        >
                          <CardContent>
                            <CardLink
                              to={PATH_DASHBOARD.jobPosts.getJobPostUrl(
                                item.id
                              )}
                            >
                              <Box
                                style={{
                                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
                                  padding: '30px',
                                  borderRadius: '50%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginRight: '15px'
                                }}
                              >
                                <Avatar
                                  src={base.logo}
                                  sx={{
                                    mr: 3,
                                    width: '63px',
                                    height: '63px',
                                    marginRight: 0
                                  }}
                                  alt="logo"
                                />
                              </Box>
                              <Grid width="100%">
                                <Box
                                  style={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    justifyContent: 'space-between'
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: '#131417',
                                      fontSize: '12px',
                                      fontWeight: 400,
                                      marginBottom: '30px'
                                    }}
                                  >
                                    {fDateJobPOst(item.created_at)}
                                  </Typography>
                                  <Box sx={{ textAlign: 'end' }}>
                                    {!item.pinned ? (
                                      <Button
                                        variant="text"
                                        onClick={(e) =>
                                          setPinnedJob(item.id, e)
                                        }
                                      >
                                        Pin job
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="text"
                                        onClick={(e) =>
                                          setPinnedJob(item.id, e)
                                        }
                                      >
                                        Unpin Job
                                      </Button>
                                    )}
                                  </Box>
                                </Box>

                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    rowGap: '8px'
                                  }}
                                >
                                  <Link
                                    variant="body2"
                                    style={{
                                      fontFamily: 'Rubik',
                                      fontSize: '20px',
                                      fontWeight: 700,
                                      flex: '1',
                                      overflow: 'hidden',
                                      display: '-webkit-box',
                                      WebkitBoxOrient: 'vertical',
                                      wordWrap: 'break-word',
                                      WebkitLineClamp: 4,
                                      textOverflow: 'ellipsis'
                                    }}
                                  >
                                    {item.title}
                                  </Link>
                                  <Typography
                                    sx={{
                                      color: '#C9CAD9',
                                      fontSize: '16px'
                                    }}
                                  >
                                    {item.customer || ''}
                                  </Typography>
                                </Box>
                              </Grid>
                            </CardLink>
                          </CardContent>
                        </Card>
                      </Grid>
                    )
                )
              : getNoJobPostsSection()}
          </Grid>
        </MainContainer>
      )}
    </>
  );
};

export const DashboardToDoPosts = () => {
  const [type, setType] = useState<string>('');
  const [modalToDo, setModalToDo] = useState(false);
  const { PERIOD_TYPES } = getTranslatedList();
  const {
    user: { role }
  } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    ToDoPosts: { results },
    mostViewedPeriod,
    isLoading
  }: any = useSelector((state: RootState) => state.toDo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (role === 'candidate' || role === 'employee') {
      window.location.reload();
      navigate(PATH_DASHBOARD.candidates.getCandidateProfileUrl('me'));
    }
  }, [role]);

  const setOption = (value: string) => {
    dispatch(getToDoPostData(value, 5));
  };
  useEffect(() => {
    dispatch(getToDoPostData(mostViewedPeriod, 5));
  }, []);

  const TodoTitle = t("Let's start creating your to do’s");

  return (
    <>
      {isLoading ? (
        <Box sx={{ px: 24, py: 12 }}>
          <LoadingScreen />
        </Box>
      ) : (
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="end"
            sx={{ marginBottom: '19px' }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '20px',
                color: '#131417',
                marginTop: '45px',
                fontFamily: 'Rubik'
              }}
            >
              {t('My To Do`s')}
            </Typography>
            <MButton
              title={t('Create New Task')}
              MyClass="violetButton"
              style={null}
              click={() => {
                setType(mostViewedPeriod);
                setModalToDo(true);
              }}
            />
          </Grid>
          <Modal open={modalToDo}>
            <ModalToDo close={() => setModalToDo(false)} type={type} />
          </Modal>
          <MainContainer
            style={{
              padding: '30px 40px',
              borderRadius: '20px',
              background:
                'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
              boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.08)'
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <MFilter
                options={PERIOD_TYPES}
                setOption={setOption}
                activeOption={mostViewedPeriod}
                style={{ width: 'fit-content' }}
              />
              <RouterLink
                to={`${PATH_DASHBOARD.toDoList.root}?type=${mostViewedPeriod}`}
              >
                <MButton title={t('View all >>')} MyClass="bluebutton" />
              </RouterLink>
            </Box>
            <Grid
              className={results?.length > 0 ? 'custom-scroll' : ''}
              style={{ minHeight: '470px' }}
              container
            >
              {results && results.length > 0 ? (
                results.map((item: any) => (
                  <RouterLink
                    style={{ textDecoration: 'none', width: '100%' }}
                    key={item.id}
                    to={`${PATH_DASHBOARD.toDoList.root}${item.id}?type=${mostViewedPeriod}`}
                  >
                    <Box
                      sx={{
                        background: 'transparent',
                        borderBottom: '1px solid  #C9CAD9',
                        width: '100%',
                        marginRight: '20px'
                      }}
                    >
                      <CardContent className="main_todo">
                        <Grid
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flex: 1
                          }}
                        >
                          <Box
                            component="img"
                            src={`/static/img/icons/${
                              item?.completed ? 'Complete' : 'time'
                            }.svg`}
                          />
                          <Typography
                            style={{
                              color: '#131417',
                              margin: '0 auto 0 15px'
                            }}
                            className="toDo_text"
                          >
                            {item.title}
                          </Typography>
                        </Grid>
                        <Box
                          style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          {item?.completed ? (
                            <Typography
                              style={{
                                color: '#8AE98A',
                                textTransform: 'uppercase',
                                width: '120px'
                              }}
                            >
                              Completed
                            </Typography>
                          ) : (
                            <Typography
                              style={{
                                color: '#1C9DD7',
                                textTransform: 'uppercase',
                                width: '120px'
                              }}
                            >
                              {t('in progress')}
                            </Typography>
                          )}
                        </Box>
                        <Box
                          style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'end'
                          }}
                        >
                          <IconButton
                            aria-label="delete"
                            size="small"
                            style={{
                              background:
                                'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
                              width: '35px',
                              height: '35px'
                            }}
                          >
                            <ArrowForwardIcon style={{ color: 'white' }} />
                          </IconButton>
                        </Box>
                      </CardContent>
                    </Box>
                  </RouterLink>
                ))
              ) : (
                <EmptyImageContent
                  text={TodoTitle}
                  href={`${PATH_DASHBOARD.toDoList.root}?type=${mostViewedPeriod}`}
                />
              )}
            </Grid>
          </MainContainer>
        </Grid>
      )}
    </>
  );
};
