import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cloneElement, useEffect, useMemo, useRef, useState } from 'react';
import { AssessmentResultType } from 'types/Assessment';
import { RootState } from 'redux/store';
import {
  getCandidateInfo,
  getAllCandidateData,
  getCandidateActivities,
  getCandidateAssessmentResults,
  getCandidateCertifications,
  getCandidateEducations,
  getCandidateFiles,
  getCandidateITSkills,
  getCandidateLanguages,
  getCandidateSocialMedia,
  getCandidateSocialMediaExpertises,
  getCandidateWorkExperience,
  patchCandidateMainInfoData,
  selectSkillsError
} from 'redux/slices/candidate';
import Page from 'components/containers/Page';
import LoadingScreen from 'components/LoadingScreen';
import useLocales from 'hooks/useLocales';
import { LANGUAGES } from 'constants/constants';
import { getPlaceholders } from 'constants/text/placeholders';
import { splitToColumns } from 'helpers/listValues';
import { PATH_HOME } from 'routes/paths';
import { Grid } from '@material-ui/core';
import DashboardNavbar from 'layouts/dashboard/navbar/DashboardNavbar';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { Title1, TitlePrimary } from 'components/text/title';
import { useSnackbar } from 'notistack';
import useAuth from '@hooks/useAuth';
import { CandidateContactCard } from './CandidateContactCard';
import { CandidatePreferencesCard } from './CandidatePreferencesCard';
import { CandidateAssessmentGraph } from './CandidateAssessmentGraph';
import { CandidateModalCard } from './CandidateModalCard';
import { CandidatePitchCard } from './CandidatePitchCard';
import ProfileHeader from './ProfileHeader';
import ColumnedList from './ColumnedList';
import { CandidateNotes } from './CandidateNotes';
import { CandidateAssessmentForPDF } from './CandidateAssessmentFroPDF';
import ProfileHeaderPDF from './ProfileHeaderPDF';
import { CandidateProfileProps } from '../../../types/Candidate';
import { getCustomer, getCustomerFiles } from '../../../redux/slices/customrs';
import axios from 'axios';
import AIModal from 'components/AIModal';
import { CandidateSkillsCard } from './CandidateSkillsCard';

const CandidateProfile = ({ isForCostumerProfile }: CandidateProfileProps) => {
  const token = localStorage.getItem('newToken');
  const [PDFType, setPDFType] = useState('all');
  const [pdfLoading, setPDFLoading] = useState(false);
  const [base64Image, setBase64Image] = useState('');
  const [currentGroups, setCurrentGroups] = useState<any>(null);
  const [editSkillsModalOpen, setEditSkillsModalOpen] = useState(false);
  const [showAIModal, setShowAIModal] = useState(false);
  const [openAvatarUpload, setOpenAvatarUpload] = useState(false);

  // Temporarily due to request
  const [isMadeAnonymous, setIsMadeAnonymous] = useState<boolean>(true);
  const componentRef = useRef(null);
  const { id } = useParams<any>();
  const customerId = id;
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const { enqueueSnackbar } = useSnackbar();
  const { candidate, candidateLoading } = useSelector(
    (state: RootState) => state.candidate
  );

  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );
  const modules = useSelector(
    (state: RootState) => state.dashboard.companyModuleInsights
  );

  const {
    user,
    isAdmin,
    isRecruiterPlus,
    isRecruiter,
    isHiringManager,
    isReviewer,
    isEmployee,
    isCandidate
  } = useAuth();

  const isEditable = isEmployee || isCandidate;

  const candidateID = id && id !== 'me' ? id : user?.candidate_id;

  const userID = id && id === 'me' ? user.id : candidate?.mainInfo?.user || '';

  const userAssessmentResult = useSelector(
    (state: RootState) => state.candidate.assessmentResult
  );
  const { notes, customer, files } = useSelector(
    (state: RootState) => state.customers
  );
  const {
    assessmentResult,
    assessmentLoading,
    soft_skills,
    hard_skills
  }: {
    assessmentResult: AssessmentResultType;
    assessmentLoading: boolean;
    soft_skills: string[];
    hard_skills: string[];
  } = useSelector((state: RootState) => state.candidate);

  const skillsError = useSelector(selectSkillsError);

  const { result } = assessmentResult;
  const { status } = assessmentResult.assessment;
  const {
    t,
    currentLang: { value: lng }
  } = useLocales();
  const { ANONYMOUS } = getPlaceholders();

  useEffect(() => {
    const waitingForAssessmentResults = result == null && status === 2;

    const fetchAssessmentResults = () => {
      if (waitingForAssessmentResults && userID !== '') {
        dispatch(getCandidateAssessmentResults(userID));
      }
    };

    fetchAssessmentResults();

    const interval = setInterval(() => {
      if (waitingForAssessmentResults) {
        fetchAssessmentResults();
      } else {
        clearInterval(interval);
      }
    }, 15000);

    return () => clearInterval(interval);
  }, [dispatch, userID]);

  useEffect(() => {
    if (customerId && isForCostumerProfile) {
      dispatch(getCustomer(customerId));
      if (!token || (token && user?.id)) {
        dispatch(getCustomerFiles(customerId));
      }
    }
  }, [customerId, isForCostumerProfile, dispatch, token, user?.id]);

  useEffect(() => {
    if (result) {
      const currents = result ? { ...(result as any) } : null;
      setCurrentGroups(currents);
    }
  }, [result]);

  useEffect(() => {
    if (candidateID && modules && !isForCostumerProfile && !isEmployee) {
      dispatch(getAllCandidateData(candidateID, modules));
    }
  }, [dispatch, candidateID, modules, isForCostumerProfile, isEmployee]);

  useEffect(() => {
    if (userID) {
      dispatch(getCandidateAssessmentResults(userID));
    }
  }, [dispatch, userID]);

  const [reportLink, motivatorLink]: any = useMemo(() => {
    if (
      userAssessmentResult.report !== null &&
      userAssessmentResult.result !== null
    ) {
      const {
        report: { feedback, mo_de }
      }: AssessmentResultType = userAssessmentResult;

      if (
        feedback &&
        mo_de &&
        typeof feedback === 'object' &&
        typeof mo_de === 'object'
      ) {
        return [(feedback as any)[lng], (mo_de as any)[lng]];
      }
    }
    return [null, null];
  }, [userAssessmentResult, lng]);

  useEffect(() => {
    const getBase64 = async (url: string) => {
      try {
        const response = await axios.get(url, {
          responseType: 'arraybuffer'
        });
        const buffer = Buffer.from(response.data);
        const base64 = `data:image/png;base64,${buffer.toString('base64')}`;
        setBase64Image(base64);
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    };

    const candidateAvatar = candidate?.mainInfo?.avatar || '';
    if (candidateAvatar) {
      getBase64(candidateAvatar);
    }
  }, [candidate?.mainInfo?.avatar]);

  // useEffect(() => {
  //   if (openAvatarUpload) {
  //     setEditSkillsModalOpen(false);
  //   }
  //   if (editSkillsModalOpen) {
  //     setOpenAvatarUpload(false);
  //   }
  // }, [openAvatarUpload, editSkillsModalOpen]);

  const candidateAvatar =
    (candidate?.mainInfo?.anonymous && !isEditable) || isMadeAnonymous
      ? '/static/favicon/icon-256x256.png'
      : base64Image;

  const candidateName =
    candidate?.mainInfo?.anonymous && !isEditable
      ? ANONYMOUS
      : candidate?.mainInfo?.name;

  const candidateBannerImage = company.candidate_banner;

  const recruiterOrAdmin =
    isAdmin || isRecruiterPlus || isRecruiter || isHiringManager || isReviewer;

  const isCandidateProfile =
    pathname === PATH_HOME.dashboardCandidate && (isCandidate || isEmployee);

  const handleAnonymous = () => {
    dispatch(
      patchCandidateMainInfoData(candidateID, {
        anonymous: !candidate?.mainInfo?.anonymous
      })
    );
  };
  const uploadCV = async (formData: FormData) => {
    dispatch(patchCandidateMainInfoData(candidateID, formData));
    await new Promise((resolve) => setTimeout(resolve, 2000));
  };

  const handleUploadCV = async (file: any) => {
    const formData = new FormData();
    if (file) formData.append(`cv`, file);
    setShowAIModal(true);
    try {
      await uploadCV(formData);
      if (skillsError !== null) {
        enqueueSnackbar(t(skillsError), {
          variant: 'error'
        });
        setEditSkillsModalOpen(false);
      } else {
        setEditSkillsModalOpen(true);
      }
    } catch (error: any) {
      enqueueSnackbar(error || t('Failed to fetch skills from uploaded CV.'), {
        variant: 'error'
      });
      setEditSkillsModalOpen(false);
    } finally {
      dispatch(getCandidateInfo(candidateID));
      setShowAIModal(false);
    }
  };

  const createOnInit = (dispatchAction: any, condition?: boolean) => () => {
    if (!isEmployee) {
      if (condition) {
        dispatch(dispatchAction(candidateID));
        return;
      }
      dispatch(dispatchAction(candidateID));
    }
  };

  const cards = [];
  const cardsCount = [];
  if (!token || isForCostumerProfile) {
    cards.push(
      <CandidateContactCard
        title={!isForCostumerProfile ? t('Contact info') : t('Info')}
        mainInfo={!isForCostumerProfile ? candidate.mainInfo : customer}
        recruiterOrAdmin={recruiterOrAdmin}
        language={
          !modules.preference && candidate.mainInfo.default_lang
            ? LANGUAGES[Number(candidate.mainInfo.default_lang) - 1].label
            : ''
        }
        isForCustomer={isForCostumerProfile}
        customerId={customerId || ''}
        customer={customer}
        calendar
      />
    );
  }

  if (modules.it_skill && !isEmployee) {
    const component = (
      <CandidateModalCard
        title={t('IT Skills')}
        type="it-skills"
        candidateId={candidateID}
        editable={isEditable}
        dataList={candidate.it_skill}
        onInit={createOnInit(getCandidateITSkills, modules.it_skill)}
      />
    );
    if (!isForCostumerProfile) {
      cardsCount.push(component);
      cards.push(component);
    }
  }

  if (!isEmployee) {
    const component = (
      <CandidateSkillsCard
        title={t('Skills')}
        type="skills"
        isCandidate={isCandidate}
        candidateId={candidateID}
        editSkillsModalOpen={editSkillsModalOpen}
        setEditSkillsModalOpen={setEditSkillsModalOpen}
        initialHardSkills={{
          title: 'hard_skills',
          data: candidate.mainInfo.hard_skills || []
        }}
        initialSoftSkills={{
          title: 'soft_skills',
          data: candidate.mainInfo.soft_skills || []
        }}
        hardSkills={{ title: 'hard_skills', data: hard_skills || [] }}
        softSkills={{ title: 'soft_skills', data: soft_skills || [] }}
        isEditable={isEditable}
        onInit={createOnInit(getCandidateInfo)}
      />
    );
    if (!isForCostumerProfile) {
      cardsCount.push(component);
      cards.push(component);
    }
  }

  if (modules.extra && !isEmployee) {
    const component = (
      <CandidateModalCard
        title={t('Activities')}
        type="activities"
        candidateId={candidateID}
        editable={isEditable}
        dataList={candidate.activities}
        onInit={createOnInit(getCandidateActivities, modules.extra)}
        iconSrc="/static/img/profile/activities.svg"
      />
    );
    if (!isForCostumerProfile) {
      cardsCount.push(component);
      cards.push(component);
    }
  }

  if (modules.pitch && !isEmployee) {
    const component = (
      <CandidatePitchCard
        candidateId={candidateID}
        editable={isEditable}
        mainInfo={candidate.mainInfo || null}
        candidate={true}
      />
    );
    if (!isForCostumerProfile) {
      cardsCount.push(component);
      cards.push(component);
    }
  }

  if (modules.certification && !isEmployee) {
    const component = (
      <CandidateModalCard
        title={t('Certifications')}
        type="certifications"
        candidateId={candidateID}
        editable={isEditable}
        dataList={candidate.certifications}
        onInit={createOnInit(getCandidateCertifications, modules.certification)}
        iconSrc="/static/img/profile/certified.svg"
      />
    );
    if (!isForCostumerProfile) {
      cardsCount.push(component);
      cards.push(component);
    }
  }

  const isAnonymous = !recruiterOrAdmin && !isCandidateProfile && !!token;

  if ((isRecruiter || isAdmin || token) && (!token || isForCostumerProfile)) {
    cards.push(
      <CandidateNotes
        addNoteTitle={
          isForCostumerProfile
            ? t('Notes')
            : t('Add your notes for this candidate')
        }
        title={t('Candidate Log')}
        candidateId={isForCostumerProfile ? customerId : candidateID}
        dataList={isForCostumerProfile ? notes : candidate.notes}
        type="notes"
        mainInfo={isForCostumerProfile ? customer : candidate?.mainInfo}
        isAnonymous={isAnonymous}
        iconSrc="/static/img/profile/notes.svg"
        isForCostumerProfile={isForCostumerProfile}
        customerId={customerId}
      />
    );
  }

  // to be discussed
  // if (!recruiterOrAdmin && !token && !isCandidateProfile) {
  //   navigate('/');
  // }

  if (modules.preference && !isEmployee) {
    const component = (
      <CandidatePreferencesCard
        candidateId={candidateID}
        editable={isEditable}
        mainInfo={candidate.mainInfo || null}
      />
    );
    if (!isForCostumerProfile) {
      cardsCount.push(component);
      cards.push(component);
    }
  }

  if (modules.social_media && !isEmployee) {
    const component = (
      <CandidateModalCard
        title={t('Social Media')}
        type="social-media"
        candidateId={candidateID}
        editable={isEditable}
        dataList={!isMadeAnonymous ? candidate.social_media : ''}
        onInit={createOnInit(getCandidateSocialMedia, modules.social_media)}
      />
    );
    if (!isForCostumerProfile) {
      cardsCount.push(component);
      cards.push(component);
    }
  }

  if (modules.social_media_expertise && !isEmployee) {
    const component = (
      <CandidateModalCard
        title={t('Social Media Expertises')}
        type="social-media-expertises"
        candidateId={candidateID}
        editable={isEditable}
        dataList={candidate.social_media_expertise}
        onInit={createOnInit(
          getCandidateSocialMediaExpertises,
          modules.social_media_expertise
        )}
      />
    );
    if (!isForCostumerProfile) {
      cardsCount.push(component);
      cards.push(component);
    }
  }

  if (modules.language && !isEmployee) {
    const component = (
      <CandidateModalCard
        title={t('Languages')}
        type="languages"
        candidateId={candidateID}
        editable={isEditable}
        dataList={candidate.languages}
        onInit={createOnInit(getCandidateLanguages, modules.language)}
      />
    );
    if (!isForCostumerProfile) {
      cardsCount.push(component);
      cards.push(component);
    }
  }

  if (!isEmployee && !token) {
    cards.push(
      <CandidateModalCard
        title={t('Upload Files')}
        type="upload_files"
        candidateId={isForCostumerProfile ? customerId : candidateID}
        editable={isEditable}
        dataList={isForCostumerProfile ? files : candidate.files}
        onInit={createOnInit(getCandidateFiles, !token)}
        isForCostumerProfile={isForCostumerProfile}
        customerId={customerId}
      />
    );
  }

  if (modules.education && !isEmployee) {
    const component = (
      <CandidateModalCard
        title={t('Educations')}
        type="educations"
        candidateId={candidateID}
        editable={isEditable}
        dataList={candidate.educations}
        onInit={createOnInit(getCandidateEducations, modules.education)}
      />
    );
    if (!isForCostumerProfile) {
      cardsCount.push(component);
      cards.push(component);
    }
  }

  const workExperiences = modules.work_experience && !isEmployee && (
    <CandidateModalCard
      title={t('Work Experiences')}
      type="work-experiences"
      candidateId={candidateID}
      editable={isEditable}
      dataList={candidate.work_experience}
      onInit={createOnInit(getCandidateWorkExperience, modules.work_experience)}
    />
  );

  if (!isForCostumerProfile) {
    cards.push(workExperiences);
  }

  const cardsToRender = isEmployee ? [] : cards;

  const columns = splitToColumns(cardsToRender);

  if (candidateLoading) return <LoadingScreen />;

  const downloadPDFFile = async (type: string) => {
    setPDFType(type);
    setPDFLoading(true);
    setTimeout(async () => {
      const elements: any = componentRef.current;
      const asyncFunctions = [];
      for (let i = 0; i < elements.children.length; i++) {
        const element = elements.children[i];
        if (elements.children[i].children.length) {
          asyncFunctions.push(html2canvas(element));
        }
      }
      const results = await Promise.all(asyncFunctions);
      const pdf = new JsPDF();
      for (let i = 0; i < results.length; i++) {
        const canvas = results[i];
        const data = canvas.toDataURL('image/jpeg', 0.6);
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
          (imgProperties.height * pdfWidth) / imgProperties.width;
        if (i) pdf.addPage();
        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
      }
      pdf.save(`${!isMadeAnonymous ? candidateName : 'Anonymous'}.pdf`);
      setPDFLoading(false);
      pdf.close();
    }, 1000);
  };

  const arrForInfo: any = [];
  for (let i = 0; i < cardsCount.length; i++) {
    if (!arrForInfo[Math.floor(i / 2)]) {
      arrForInfo.push([cardsCount[i]]);
    } else if (arrForInfo[Math.floor(i / 2)]) {
      arrForInfo[Math.floor(i / 2)].push(cardsCount[i]);
    }
  }

  if (workExperiences) {
    arrForInfo.push([workExperiences]);
  }
  const arrayForContent = [];
  for (let i = 0; i < Math.ceil([...arrForInfo].length / 2); i++) {
    arrayForContent.push(i);
  }

  let titleNavbar;

  if (isForCostumerProfile) {
    titleNavbar = 'Customer Profile';
  } else if (recruiterOrAdmin) {
    titleNavbar = 'Candidate Profile';
  } else {
    titleNavbar = 'My Profile';
  }

  return (
    <>
      <AIModal
        open={showAIModal}
        onClose={() => setShowAIModal(false)}
        customMessage={t('NORA is extracting the skills from your CV')}
      />
      <Page
        title={`${t('Candidate')} | ${candidate?.mainInfo?.name}`}
        style={{
          background: '#ffffff'
        }}
      >
        <DashboardNavbar title={titleNavbar} />
        <ProfileHeader
          candidate={candidate}
          isEditable={isEditable}
          anonymous={candidate?.mainInfo?.anonymous}
          candidateAvatar={isForCostumerProfile ? '' : candidateAvatar}
          candidateName={isForCostumerProfile ? customer?.name : candidateName}
          handleUploadCV={handleUploadCV}
          openAvatarUpload={openAvatarUpload}
          setOpenAvatarUpload={setOpenAvatarUpload}
          handleAnonymous={handleAnonymous}
          reportLink={reportLink}
          motivatorLink={motivatorLink}
          recruiterOrAdmin={recruiterOrAdmin}
          downloadPDFFile={downloadPDFFile}
          pdfLoading={pdfLoading}
          type={PDFType}
          isForCostumerProfile={isForCostumerProfile}
          customerId={customerId}
          isAnonymous={isMadeAnonymous}
          setIsAnonymous={(e: boolean) => setIsMadeAnonymous(e)}
          candidateBannerImage={candidateBannerImage}
        />
        <ColumnedList columns={columns} />
        <Grid
          style={{
            padding: '0 30px'
          }}
        >
          {!isForCostumerProfile && (
            <CandidateAssessmentGraph
              result={result}
              id={candidate?.mainInfo?.id}
              status={status}
              editable={isEditable}
              iconSrc="/static/img/profile/professional-skills.svg"
            />
          )}
        </Grid>
      </Page>
      {recruiterOrAdmin && (
        <div
          style={{
            height: 0,
            overflow: 'hidden'
          }}
        >
          <div
            id="divToPrint"
            style={{
              maxWidth: '1200px',
              minWidth: '1200px',
              background: '#FFFFFF'
            }}
            ref={componentRef}
          >
            {PDFType != 'with-charts' &&
              arrayForContent.map((elem, indexY) => (
                <Grid
                  key={indexY}
                  style={{ height: '1700px', position: 'relative' }}
                >
                  <ProfileHeaderPDF
                    candidate={candidate}
                    candidateAvatar={
                      !isMadeAnonymous
                        ? base64Image
                        : '/static/img/favicon/icon-192x192.png'
                    }
                    candidateName={
                      !isMadeAnonymous ? candidateName : 'Anonymous'
                    }
                  />
                  {[...(PDFType != 'with-charts' ? arrForInfo : [])]?.map(
                    (item: any, index: number) => {
                      if (index < indexY * 2 + 2 && index >= indexY * 2) {
                        return (
                          <Grid
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                            key={index}
                          >
                            {item.map((el: any, indexI: number) => (
                              <Grid
                                style={{
                                  width: item.length == 1 ? '1200px' : '400px'
                                }}
                                key={indexI}
                              >
                                {cloneElement(el, { withoutBox: true })}
                              </Grid>
                            ))}
                          </Grid>
                        );
                      }
                      return null;
                    }
                  )}
                  <Title1
                    style={{
                      textAlign: 'right',
                      position: 'absolute',
                      bottom: '20px',
                      right: '20px'
                    }}
                  >
                    {indexY + 1}
                  </Title1>
                </Grid>
              ))}
            {PDFType != 'with-info' && currentGroups && (
              <Grid style={{ height: '1700px', position: 'relative' }}>
                <ProfileHeaderPDF
                  candidate={candidate}
                  candidateAvatar={
                    !isMadeAnonymous
                      ? base64Image
                      : '/static/img/favicon/icon-192x192.png'
                  }
                  candidateName={!isMadeAnonymous ? candidateName : 'Anonymous'}
                />
                <Title1 style={{ textAlign: 'center' }}>
                  {t('Your Personality Profile')}
                </Title1>
                <Grid
                  style={{
                    padding: '0 30px'
                  }}
                >
                  <CandidateAssessmentForPDF
                    pageCount={0}
                    repeat={false}
                    currentGroups={currentGroups}
                    assessmentLoading={assessmentLoading}
                  />
                </Grid>
                <Title1
                  style={{
                    textAlign: 'right',
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px'
                  }}
                >
                  {PDFType == 'with-charts' ? 1 : arrayForContent?.length + 1}
                </Title1>
              </Grid>
            )}
            {PDFType != 'with-info' && currentGroups && (
              <Grid style={{ height: '1700px', position: 'relative' }}>
                <ProfileHeaderPDF
                  candidate={candidate}
                  candidateAvatar={
                    !isMadeAnonymous
                      ? base64Image
                      : '/static/img/favicon/icon-192x192.png'
                  }
                  candidateName={!isMadeAnonymous ? candidateName : 'Anonymous'}
                />
                <Title1 style={{ textAlign: 'center' }}>
                  {t('Your Personality Profile')}
                </Title1>
                <Grid
                  style={{
                    padding: '0 30px'
                  }}
                >
                  <CandidateAssessmentForPDF
                    pageCount={4}
                    currentGroups={currentGroups}
                    assessmentLoading={assessmentLoading}
                  />
                </Grid>
                <TitlePrimary
                  style={{ textAlign: 'center', padding: '0 40px' }}
                >
                  {t(
                    'Explanation of scores: score 1-3 is low aptitude. 4-6 is medium aptitude. 7-8 is high aptitude. 9 is very high aptitude.'
                  )}
                </TitlePrimary>
                <Title1
                  style={{
                    textAlign: 'right',
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px'
                  }}
                >
                  {PDFType == 'with-charts' ? 2 : arrayForContent?.length + 2}
                </Title1>
              </Grid>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CandidateProfile;
