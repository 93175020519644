import { Box, CardContent, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SignUpTooltip } from 'components/authentication/register/components';
import { getInitialValuesByType } from '../../../helpers/formInitialValues';
import useLocales from '../../../hooks/useLocales';

import { patchCandidateMainInfoData } from '../../../redux/slices/candidate';
import { CloseButton } from '../../Buttons';
import { DynamicFormWrapper } from '../../DynamicForm';
import { CardWrapper } from '../../../styled';

export const CandidatePreferencesForm = ({
  initialData,
  candidateId,
  onNext,
  onClose,
  withOutLang
}: any) => {
  const type = 'candidate-preferences-info';
  const { t } = useLocales();

  const [itemValues, setItemValues] = useState(
    getInitialValuesByType(type, initialData, withOutLang)
  );

  const setNewValues = (field: string, value: any) => {
    setItemValues({
      ...itemValues,
      [field]: value
    });
  };

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (itemValues.salary === 6) {
      itemValues.salary = null;
    }
    if (itemValues) {
      const data = {
        ...itemValues,
        default_lang:
          itemValues?.default_lang == 3 ? null : itemValues?.default_lang
      };
      dispatch(
        patchCandidateMainInfoData(candidateId, {
          ...data,
          industries: itemValues.industries.map((el: any) => el?.id || el)
        })
      );
    }
    if (onNext) onNext();
  };

  return (
    <CardWrapper>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">{t('Preferences')}</Typography>
          {onClose && <CloseButton onClick={onClose} />}
        </Box>
        <SignUpTooltip title={t('preference_tooltip')} />
        <DynamicFormWrapper
          type="candidate-preferences-info"
          itemValues={itemValues}
          setValues={setNewValues}
          onSubmit={handleSubmit}
          withOutLang={withOutLang}
        />
      </CardContent>
    </CardWrapper>
  );
};
