import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Zoom
} from '@material-ui/core';
import {
  AddOutlined as AddOutlinedIcon,
  GetAppOutlined as GetAppOutlinedIcon,
  DescriptionOutlined as DescriptionIcon,
  Clear as ClearIcon
} from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from 'hooks/useAuth';
import {
  changeFilters,
  createNewCandidate,
  getAllUserAndAdmin,
  editInactiveCandidate,
  deleteInactiveCandidate
} from 'redux/slices/candidate';
import { RootState } from 'redux/store';
import { setMatchCandidatesFilter } from 'redux/slices/filters';
import { SocialMediaExpertiseResult } from 'constants/states';
import { Search, NotInterested, EditOutlined } from '@material-ui/icons';

import { styled } from '@mui/material';
import PaginationContainer from 'components/containers/PaginationContainer';
import * as ExcelJS from 'exceljs';
import { ButtonPrimary } from 'components/new-designer/button/ButtonPrimary';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import useLocales from '../../hooks/useLocales';
import PageContainer from '../../components/containers/PageContainer';
import { PATH_DASHBOARD } from '../../routes/paths';
import Page from '../../components/containers/Page';
import DashboardNavbar from '../../layouts/dashboard/navbar/DashboardNavbar';
import MButton from '../../components/new-designer/button/MButton';

import { getButtons } from 'constants/text/buttons';
import { getCandidateRemind, sendInvitation } from 'requests/candidate';
import { useSnackbar } from 'notistack';
import { DEFAULT_MESSAGE_TEXTS } from 'constants/constants';
import { CustomModal } from 'components/CustomModal';
import { DynamicFormWrapper } from 'components/DynamicForm';
import FileUploader from 'components/FileUploader';
import { DeleteButton } from '@components/Buttons';
import { deleteDataByUrl } from 'requests';

interface CandidateInfo {
  [key: string]: any;
}

const InputTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    background: #ffffff;
    border: 1px solid #c9cad9;
    border-radius: 30px;
  }
`;

const CultureTable = styled(Table)(() => ({
  '@media (max-width: 1024px)': {
    tr: {
      display: 'flex',
      flexDirection: 'column',
      height: 'max-content',
      padding: '10px',
      td: {
        padding: '10px 0 !important',
        display: 'flex',
        flexWrap: 'wrap',
        wordBreak: 'break-word'
      }
    },
    thead: {
      tr: {
        display: 'none'
      }
    }
  }
}));

const TypographyTable = styled(Table)(() => ({
  display: 'none',
  '@media (max-width: 1024px)': {
    display: 'block',
    width: 'max-content'
  }
}));
const FilterGrid = styled(Grid)(() => ({
  '@media (max-width: 1024px)': {
    flexDirection: 'column',
    div: {
      maxWidth: '100%'
    },
    gap: '20px'
  }
}));

const candidateDefaultInfo = {
  email: '',
  first_name: '',
  last_name: '',
  gender: null,
  linkedin_url: '',
  phone: '',
  birthday: null,
  job: null,
  cv: null
};

const TalentPool = ({ style = {} }) => {
  const {
    userId,
    isAdmin,
    isRecruiterPlus,
    isRecruiter,
    isHiringManager,
    isReviewer
  } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { social_media_expertise } = useSelector(
    (state: RootState) => state.candidate.candidate
  );
  const { currentPage, currentRole } = useSelector(
    (state: RootState) => state.candidate.talentFilters
  );
  const [allCandidatesBtn, setAllCandidatesBtn] = useState<any>(true);
  const [candidatesBtn, setCandidatesBtn] = useState<any>(false);
  const [employeeBtn, setEmployeeBtn] = useState<any>(false);
  const [showCandidateModal, setShowCandidateModal] = useState(false);
  const [candidateInfo, setCandidateInfo] = useState<any>(candidateDefaultInfo);
  const [cvFile, setCvFile] = useState<File>();
  const [arrayCandidate, setArrayCandidate] =
    useState<SocialMediaExpertiseResult[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [targetCandidateId, setTargetCandidateId] = useState(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { query } = useSelector(
    (state: RootState) => state.filtersReducer.matchCandidatesOverview
  );
  const { t } = useLocales();
  const { FIND_BEST_MATCHES, UPLOAD, ACCEPT, DECLINE, DELETE } = getButtons();
  const perPage = 20;
  const title = t('Talent Pool');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (userId) {
      dispatch(getAllUserAndAdmin(userId, currentRole, query, currentPage));
    }
  }, [dispatch, query, userId]);

  useEffect(() => {
    if (social_media_expertise?.results)
      setArrayCandidate(
        allCandidatesBtn
          ? social_media_expertise?.results
          : social_media_expertise.results.filter(
              (candidate) => candidate.role === currentRole
            )
      );
  }, [allCandidatesBtn, currentRole, social_media_expertise]);

  const isRecruiterOrAdmin = isRecruiter || isAdmin;

  const canTakeAction =
    isAdmin || isRecruiterPlus || isRecruiter || isHiringManager;

  const canSeeProfile = canTakeAction || isReviewer;

  const canExportCandidates = !(isHiringManager || isReviewer);

  const onClick = async () => {
    const data: any = [['Role', 'Full name', 'Email', 'Profile link']];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    if (arrayCandidate) {
      for (let i = 0; i < arrayCandidate.length; i++) {
        const elem = arrayCandidate[i];
        data.push([
          elem.role,
          `${elem.first_name} ${elem.last_name}`,
          elem.email,
          `${window.origin}${PATH_DASHBOARD.candidates.getCandidateProfileUrl(
            elem.candidate_id
          )}`
        ]);
      }
    }

    worksheet.addRows(data);
    worksheet.getColumn('A').width = 20;
    worksheet.getColumn('B').width = 30;
    worksheet.getColumn('C').width = 40;
    worksheet.getColumn('D').width = 60;
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'candidate-export-Recrout.xlsx';
      a.click();
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  };

  const handleRemind = async (id: number) => {
    const res = await getCandidateRemind(id);
    try {
      if (res) {
        enqueueSnackbar('Reminded successfully', { variant: 'success' });
      }
    } catch {
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.error, { variant: 'error' });
    }
  };

  const setNewCandidateInfo = (field: string, value: any) => {
    setCandidateInfo({
      ...candidateInfo,
      [field]: value
    });
  };

  const handleUploadCV = (file: any) => {
    setCvFile(file);
    const formData = new FormData();
    if (file) formData.append(`cv`, file);
  };

  const handleCandidateSubmit = () => {
    const { job, ...rest } = candidateInfo;
    const job_id = job?.id || null;
    const data = {
      ...rest,
      job_id
    };

    const formData = new FormData();

    if (cvFile) {
      formData.append(`cv`, cvFile);
      formData.append('first_name', rest.first_name);
      formData.append('last_name', rest.last_name);
      formData.append(`email`, rest.email);
      formData.append('gender', rest.gender || '');
      formData.append('linkedin_url', rest.linkedin_url || '');
      formData.append('phone', rest.phone);
      formData.append('birthday', rest.birthday || '');
      formData.append('job_id', job_id || '');
    }

    const requestBody = cvFile ? formData : data;
    if (isEditing) {
      dispatch(editInactiveCandidate(requestBody, targetCandidateId));
    } else {
      dispatch(createNewCandidate(requestBody));
    }

    setIsEditing(false);
    setTargetCandidateId(null);
    setShowCandidateModal(false);
    setCandidateInfo(candidateDefaultInfo);
    dispatch(getAllUserAndAdmin(userId, currentRole, '', 1));
    setCvFile(undefined);
  };

  const renderFileUploader = () =>
    cvFile ? (
      <Box
        sx={{
          mx: 1,
          maxHeight: '36px',
          width: '155px',
          maxWidth: '155px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ':hover': {
            '& #file-icon': {
              display: 'none'
            },
            '& #clear': {
              display: 'flex',
              justifyContent: 'start'
            },
            '& #clear-icon': {
              display: 'flex'
            }
          }
        }}
      >
        <Typography>{t('CV:')}</Typography>
        <Tooltip
          arrow
          placement="top"
          TransitionComponent={Zoom}
          title={cvFile.name}
        >
          <Box width={22} height={22} mb="2px">
            <DescriptionIcon
              id="file-icon"
              sx={{ width: 22, color: 'black' }}
            />

            <Button
              id="clear"
              onClick={() => {
                setCandidateInfo({
                  ...candidateInfo,
                  cv: null
                });
                setCvFile(undefined);
              }}
              sx={{
                position: 'absolute',
                display: 'none',
                padding: 0,
                ':hover': { backgroundColor: 'transparent' }
              }}
            >
              <ClearIcon
                id="clear-icon"
                sx={{
                  width: 22,
                  color: 'red',
                  display: 'none'
                }}
              />
            </Button>
          </Box>
        </Tooltip>
      </Box>
    ) : (
      <FileUploader
        sx={{ mx: 1, maxHeight: '36px', width: '155px' }}
        variant="contained"
        onSubmit={handleUploadCV}
      >
        {UPLOAD} CV
      </FileUploader>
    );

  const handleInvite = async (id: number) => {
    const res = await sendInvitation(id);
    try {
      if (res) {
        enqueueSnackbar('Invited successfully', { variant: 'success' });
      }
    } catch {
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.error, { variant: 'error' });
    }
  };

  const handleDeleteCandidate = async (candidateId: string) => {
    try {
      await deleteDataByUrl(`/api/account/${candidateId}/`);
      dispatch(getAllUserAndAdmin(userId, currentRole, query, currentPage));
    } catch (error) {
      console.error(error);
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.error, { variant: 'error' });
    }
  };

  const renderButtons = ({
    row,
    isRecruiterOrAdmin,
    handleRemind,
    handleInvite,
    t,
    RouterLink,
    PATH_DASHBOARD
  }: {
    row: any;
    isRecruiterOrAdmin: boolean;
    handleRemind: (id: number) => Promise<void>;
    handleInvite: (id: number) => Promise<void>;
    t: any;
    RouterLink: any;
    PATH_DASHBOARD: any;
  }) => {
    if (!row?.is_active && canTakeAction) {
      return (
        <>
          <ButtonPrimary
            style={{
              width: '100%',
              borderRadius: '30px',
              cursor: 'pointer',
              maxWidth: '120px'
            }}
            onClick={() => handleInvite(row?.id)}
          >
            {t('Invite')}
          </ButtonPrimary>
          <Box display="flex" alignItems="center" gap={1}>
            <MButton
              title={t('Edit')}
              MyClass="violetButton"
              StartIcon={
                <EditOutlined
                  sx={{
                    fontSize: '1.3 rem',
                    color: 'white',
                    mr: '5.5px'
                  }}
                />
              }
              style={{ width: '120px' }}
              click={() => {
                insertCandidateInfo(row);
                setShowCandidateModal(true);
                setIsEditing(true);
                setTargetCandidateId(row.candidate_id);
              }}
            />
            {/* <Box display="flex" my="15px">
              <DeleteDialog
                open={showModal}
                title={t('Are you sure you want to delete this candidate?')}
                onClose={() => setShowModal(false)}
                onDelete={deleteCandidate}
              />
              <MButton
                title={t('Delete')}
                MyClass="redButton"
                StartIcon={
                  <NotInterested
                    sx={{
                      fontSize: '1.3 rem',
                      color: 'white',
                      mr: '5.5px'
                    }}
                  />
                }
                click={() => {
                  setTargetCandidateId(row.candidate_id);
                  setShowModal(true);
                }}
              />
            </Box> */}
          </Box>
        </>
      );
    }

    return canTakeAction ? (
      <Box
        width="100%"
        display="flex"
        flexWrap="wrap"
        gap="16px"
        justifyContent="flex-start"
      >
        {!row?.if_taken_assessment && (
          <ButtonPrimary
            style={{
              minWidth: '200px',
              maxWidth: '100%',
              borderRadius: '30px',
              cursor: 'pointer',
              flexGrow: 1
            }}
            onClick={() => handleRemind(row?.candidate_id)}
          >
            {t('Remind the candidate to take personality assessment')}
          </ButtonPrimary>
        )}
        <ButtonPrimary
          style={{
            minWidth: '200px',
            maxWidth: '100%',
            borderRadius: '30px',
            cursor: 'pointer',
            flexGrow: 1
          }}
          variant="contained"
          color="primary"
          component={RouterLink}
          to={PATH_DASHBOARD.jobPosts.getBestMatchesJobUrl(row.candidate_id)}
        >
          {FIND_BEST_MATCHES}
        </ButtonPrimary>
        {isAdmin && (
          <DeleteButton
            style={{
              minWidth: '200px',
              maxWidth: '100%',
              borderRadius: '30px',
              cursor: 'pointer',
              flexGrow: 1
            }}
            variant="contained"
            color="error"
            iconColor="white"
            onClick={() => handleDeleteCandidate(row.id)}
            buttontext={DELETE}
          />
        )}
      </Box>
    ) : (
      <></>
    );

    return null;
  };

  const insertCandidateInfo = (candidateData: { [key: string]: any }) => {
    const candidateFields = [
      'email',
      'first_name',
      'last_name',
      'gender',
      'linkedin_url',
      'phone',
      'birthday',
      'job',
      'cv'
    ];

    const fieldsToUpdate = candidateFields.reduce<CandidateInfo>(
      (acc, field) => {
        if (Object.prototype.hasOwnProperty.call(candidateData, field)) {
          if (field === 'job') {
            const job = candidateData?.job;
            acc.job = job
              ? {
                  name: job.title,
                  label: job.title,
                  id: job.id
                }
              : undefined;
          } else {
            acc[field] = candidateData[field];
            setCvFile(candidateData?.cv);
          }
        }
        return acc;
      },
      {}
    );

    setCandidateInfo((prevState: any) => ({
      ...prevState,
      ...fieldsToUpdate
    }));
  };

  const deleteCandidate = () => {
    dispatch(deleteInactiveCandidate(targetCandidateId));
    setShowModal(false);
    setTargetCandidateId(null);
    dispatch(getAllUserAndAdmin(userId, currentRole, '', 1));
  };

  return (
    <Page title={title}>
      <DashboardNavbar title={title} />
      <PageContainer style={style}>
        <FilterGrid
          container
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'nowrap',
            marginBottom: '20px'
          }}
        >
          <Grid
            item
            xs={5}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            {/* <Button variant="text" onClick={() => filterRole('all_candidates')}> */}
            {/*  {t('All Candidates')} */}
            {/* </Button> */}
            <MButton
              title={t('All Candidates')}
              style={
                allCandidatesBtn
                  ? {
                      background:
                        'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
                      color: '#FFFFFF',
                      borderRadius: '30px',
                      width: '155px',
                      height: '36px',
                      fontWeight: 700,
                      fontSize: '14px'
                    }
                  : {
                      fontFamily: 'Rubik',
                      backgroundColor: 'transparent',
                      // background: 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
                      color: '#131417;',
                      borderRadius: '30px',
                      width: '155px',
                      height: '36px',
                      fontWeight: 700,
                      fontSize: '14px'
                    }
              }
              click={() => {
                dispatch(changeFilters('currentRole', 'all_candidates'));
                setAllCandidatesBtn(true);
                setCandidatesBtn(false);
                setEmployeeBtn(false);
              }}
            />
            <MButton
              title={t('Candidate')}
              style={
                candidatesBtn
                  ? {
                      background:
                        'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
                      color: '#FFFFFF',
                      borderRadius: '30px',
                      width: '155px',
                      height: '36px',
                      fontWeight: 700,
                      fontSize: '14px'
                    }
                  : {
                      fontFamily: 'Rubik',
                      backgroundColor: 'transparent',
                      // background: 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
                      color: '#131417;',
                      borderRadius: '30px',
                      width: '155px',
                      height: '36px',
                      fontWeight: 700,
                      fontSize: '14px'
                    }
              }
              click={() => {
                dispatch(changeFilters('currentRole', 'candidate'));
                setCandidatesBtn(true);
                setAllCandidatesBtn(false);
                setEmployeeBtn(false);
              }}
            />
            <MButton
              title={t('Employee')}
              style={
                employeeBtn
                  ? {
                      background:
                        'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
                      color: '#FFFFFF',
                      borderRadius: '30px',
                      width: '120px',
                      height: '36px',
                      fontWeight: 700
                    }
                  : {
                      fontFamily: 'Rubik',
                      backgroundColor: 'transparent',
                      // background: 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
                      color: '#131417;',
                      borderRadius: '30px',
                      width: '120px',
                      height: '36px',
                      fontWeight: 700,
                      fontSize: '14px'
                    }
              }
              click={() => {
                dispatch(changeFilters('currentRole', 'employee'));
                setEmployeeBtn(true);
                setAllCandidatesBtn(false);
                setCandidatesBtn(false);
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              flexDirection: { xs: 'column', md: 'row' },
              width: '100%'
            }}
          >
            <InputTextField
              sx={{ width: '100%' }}
              value={query}
              className="candidateSearch"
              placeholder="Search"
              onChange={(event: any) => {
                dispatch(changeFilters('currentPage', 1));
                dispatch(
                  setMatchCandidatesFilter('query', event?.target.value)
                );
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search color="primary" />
                  </InputAdornment>
                )
              }}
            />
            <Box
              sx={{
                width: 'auto',
                display: 'flex',
                justifyContent: { xs: 'start', lg: 'end' },
                gap: 1
              }}
            >
              {canExportCandidates && (
                <MButton
                  title={t('Export')}
                  MyClass="violetButton"
                  StartIcon={<GetAppOutlinedIcon />}
                  click={onClick}
                  style={{
                    minWidth: '140px',
                    color: 'white',
                    borderRadius: '30px'
                  }}
                />
              )}
              {canTakeAction && (
                <MButton
                  title={t('Add Candidate')}
                  MyClass="violetButton"
                  StartIcon={<AddOutlinedIcon />}
                  click={() => {
                    setCvFile(undefined);
                    setIsEditing(false);
                    setCandidateInfo(candidateDefaultInfo);
                    setShowCandidateModal(!showCandidateModal);
                  }}
                  style={{
                    minWidth: '140px',
                    color: 'white',
                    borderRadius: '30px'
                  }}
                />
              )}
            </Box>
          </Grid>

          {/* <OverviewSearch
           setValue={useCallback(
           (value: any) => dispatch(setMatchCandidatesFilter('query', value)),
           [dispatch]
           )}
           value={query}
           sx={{ border: '1px solid black' }}
           /> */}
        </FilterGrid>
        {showCandidateModal && (
          <CustomModal
            open={showCandidateModal}
            onClose={() => setShowCandidateModal(false)}
          >
            <Grid item xs={12} pb={3}>
              <DynamicFormWrapper
                title={t('Add Candidate')}
                type="add-candidate"
                itemValues={candidateInfo}
                onSubmit={handleCandidateSubmit}
                setValues={setNewCandidateInfo}
                FileUploader={renderFileUploader}
              />
            </Grid>
          </CustomModal>
        )}
        <div
          style={{
            background:
              'linear-gradient(rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
            padding: '0px 40px 33px 40px',
            borderRadius: '20px'
          }}
        >
          <CultureTable size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    color: '#131417',
                    fontWeight: '600',
                    fontSize: '20px',
                    backgroundColor: 'transparent',
                    paddingTop: '40px',
                    paddingBottom: '15px',
                    paddingLeft: '0px !important'
                  }}
                >
                  {t('Role')}
                </TableCell>
                <TableCell
                  sx={{
                    color: '#131417',
                    fontWeight: '600',
                    fontSize: '20px',
                    backgroundColor: 'transparent',
                    paddingTop: '40px',
                    paddingBottom: '15px',
                    paddingLeft: '0'
                  }}
                >
                  {t('FullName')}
                </TableCell>
                <TableCell
                  sx={{
                    color: '#131417',
                    fontWeight: '600',
                    fontSize: '20px',
                    backgroundColor: 'transparent',
                    paddingTop: '40px',
                    paddingBottom: '15px',
                    paddingLeft: '0',
                    boxShadow: 'none !important'
                  }}
                >
                  {t('Email')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ paddingLeft: '30px', paddingRight: '30px' }}>
              <Divider
                style={{
                  display: 'table-row'
                }}
              />
              {arrayCandidate &&
                arrayCandidate.map((row: any, index: number) =>
                  index < currentPage * perPage &&
                  index >= currentPage * perPage - perPage ? (
                    <>
                      <TableRow
                        key={row.id}
                        sx={{
                          height: '54px'
                        }}
                      >
                        <TableCell
                          sx={{
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#131417',
                            lineHeight: '19px',
                            paddingLeft: '0 !important',
                            paddingRight: '2rem'
                          }}
                        >
                          <TypographyTable>{t('Role')}:&nbsp;</TypographyTable>
                          {t(
                            `${row.role}`.charAt(0).toUpperCase() +
                              row.role.slice(1)
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#131417',
                            lineHeight: '19px',
                            padding: '20px 0',
                            paddingRight: '2rem'
                          }}
                        >
                          <TypographyTable>
                            {t('FullName')}:&nbsp;
                          </TypographyTable>
                          {t(`${row.first_name} ${row.last_name}`)}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#131417',
                            lineHeight: '19px',
                            padding: '20px 0',
                            paddingRight: '2rem'
                          }}
                        >
                          <TypographyTable>{t('Email')}:&nbsp;</TypographyTable>
                          {t(`${row.email}`)}
                        </TableCell>
                        <TableCell
                          style={{
                            display: 'flex',
                            columnGap: '10px',
                            alignItems: 'center'
                          }}
                        >
                          {canSeeProfile && (
                            <ButtonPrimary
                              onClick={() => {
                                navigate(
                                  PATH_DASHBOARD.candidates.getCandidateProfileUrl(
                                    row.candidate_id
                                  )
                                );
                              }}
                              disabled={!row.candidate_id}
                              style={{
                                background: !row.candidate_id
                                  ? 'grey'
                                  : 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
                                borderRadius: '30px',
                                py: '10px',
                                px: '20px',
                                fontWeight: '700',
                                color: '#fff',
                                textDecoration: 'none'
                              }}
                            >
                              {t('Profile')}
                            </ButtonPrimary>
                          )}

                          {renderButtons({
                            row,
                            isRecruiterOrAdmin,
                            handleRemind,
                            handleInvite,
                            t,
                            RouterLink,
                            PATH_DASHBOARD
                          })}
                        </TableCell>
                      </TableRow>
                      <Divider
                        className="divider"
                        style={{
                          display: 'table-row',
                          color: '#C9CAD9'
                        }}
                      />
                    </>
                  ) : null
                )}
            </TableBody>
          </CultureTable>
        </div>
        <PaginationContainer
          page={currentPage}
          count={arrayCandidate?.length || 0}
          perPage={perPage}
          setPage={(value) => {
            scrollToTop();
            dispatch(changeFilters('currentPage', value));
          }}
        />
      </PageContainer>
    </Page>
  );
};

export default TalentPool;
