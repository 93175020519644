import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getCardValuesByType } from 'helpers/cardValues';
import { CandidatePreferencesForm } from 'components/forms/account/CandidatePreferencesForm';
import CandidateCardContainer from 'components/containers/CandidateCardContainer';
import CandidateModalContainer from 'components/containers/CandidateModalContainer';
import CardField from 'components/CardField';
import useLocales from 'hooks/useLocales';
import { CandidateType } from 'types/Candidate';
import { SALARY_CHOICES_INR } from 'constants/constants';

interface CandidateInfoProps {
  candidateId: string | number;
  mainInfo: CandidateType;
  editable: boolean;
  onInit?: () => void;
  withoutBox?: boolean;
}

export const CandidatePreferencesCard = ({
  candidateId,
  mainInfo,
  editable,
  onInit,
  withoutBox
}: CandidateInfoProps) => {
  const type = 'candidate-preferences-info';

  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleClose = () => setEditModalOpen(false);
  const opt: any = useSelector((state: RootState) => state.fixturesReducer);
  const options = { ...opt };
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );

  if (company.currency == 'INR') {
    options.salaries = SALARY_CHOICES_INR;
  }

  const cardFields = getCardValuesByType(
    type,
    mainInfo,
    options,
    company.currency
  );

  const { t } = useLocales();

  if (cardFields[5].value === 'Not indicated') {
    cardFields[5].value = 'Undisclosed';
  }

  return (
    <CandidateCardContainer
      title={t('Preferences')}
      onEdit={editable ? () => setEditModalOpen(true) : null}
      withoutBox={withoutBox}
    >
      <CandidateModalContainer open={editModalOpen} onClose={handleClose}>
        <CandidatePreferencesForm
          initialData={mainInfo}
          candidateId={candidateId}
          onClose={handleClose}
          onNext={() => {
            onInit && onInit();
            handleClose();
          }}
        />
      </CandidateModalContainer>
      {cardFields.map(({ label, value }: any, i: number) => (
        <CardField key={i} label={label} value={value} />
      ))}
    </CandidateCardContainer>
  );
};
