import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import { setCurrentJobPostId } from 'redux/slices/productsReducer';
import { getCampaignsThunk } from 'redux/slices/campaignsReducer';
import { TestAssessmentButton } from '@components/TestAssessmentButton';
import { getCandidateInfo } from 'redux/slices/candidate';
import { OpenJobPostCard } from 'components/CardJobPost';
import DashboardNavbar from 'layouts/dashboard/navbar/DashboardNavbar';
import { ArrowBackIosNew } from '@material-ui/icons';
import { getButtons } from 'constants/text/buttons';
import { OverviewSelect } from 'components/OverviewFilter';
import { getInitialJobPostData } from 'utils/helpers';
import { postDataByUrl } from 'requests';
import AIModal from 'components/AIModal';
import { htmlToText } from 'html-to-text';
import {
  CONTRACT_TYPES,
  DEGREE_TYPES,
  FREELANCE_HOURLY_RATES,
  getTranslatedList,
  MAX_COMPETENCY_SELECTED_LENGTH,
  MIN_COMPETENCY_SELECTED_LENGTH,
  MIN_COMPETENCY_SLIDER_RANGE,
  SALARY_CHOICES,
  SALARY_CHOICES_INR,
  YEAR_CHOICES
} from '../../constants/constants';
import { HOST_URL, PATH_DASHBOARD, PATH_HOME } from '../../routes/paths';
import Page from '../../components/containers/Page';
import { updateJobPost } from '../../requests/vacancy';
import useAuth from '../../hooks/useAuth';
import LoadingScreen from '../../components/LoadingScreen';
import StepperComponent from '../../components/Stepper';
import CompetenciesSelect from '../../components/CompetenciesSelect';
import {
  fieldValidate,
  formValidate,
  makeNotRequired
} from '../../helpers/formValidation';

import { DynamicForm } from '../../components/DynamicForm';
import { EditButton } from '../../components/Buttons';
import { getComparedData } from '../../helpers/listValues';
import { RootState } from '../../redux/store';
import { ApplyJobButton } from '../../components/ApplyJobButton';
import PageContainer from '../../components/containers/PageContainer';
import { getCardValuesByType } from '../../helpers/cardValues';
import { SharedButtons } from '../../components/SharedButtons';
import { useFormFieldsByType } from '../../helpers/formFields';
import useLocales from '../../hooks/useLocales';
import ScoreField from '../../components/ScoreField';
import {
  TypographyDescriptionJobPost,
  TypographyTitleJobPost
} from '../../styled';
import CultureProfileSecondStep from './CultureProfileSecondStep';
import { JobPostCompetenciesType, JobPostType } from './types';
import {
  fetchAllJobPosts,
  fetchJob,
  patchJobPost,
  postJob,
  fetchRemoteJobs,
  resetNewJobPost
} from '../../redux/slices/jobSlice';

interface Competency {
  name: string;
  id: number;
  description: string;
}

export const initialCurrentJobPost = {
  id: null,
  user: null,
  title: '',
  description: '',
  customer: '',
  status: '',
  studies: [],
  contract: 0,
  degree: 0,
  work_exp: 0,
  salary: null,
  hourly_rate: null,
  locations: [],
  industries: [],
  skills: [],
  musts: [],
  perks: [],
  available: null,
  drivers_license: false,
  anonymous: false,
  assessment_required: false
};

const initialCurrentCompetencies = {
  competency_labels: [],
  competency_min_scores: [],
  competency_ids: [],
  descriptions: []
};

const EditJobPost = ({ options, isNew, userId, jobPostId, setEdit }: any) => {
  const { DEFAULT_MESSAGE_TEXTS } = getTranslatedList();
  const { t } = useLocales();

  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );

  const isFreeAccount = company?.if_free_trial;

  if (company.currency === 'INR') {
    options.salaries = SALARY_CHOICES_INR;
  }

  const [isLoading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState<any>({});
  const [existingJobPost, setExistingJobPost] = useState<JobPostType>(
    initialCurrentJobPost
  );
  const [currentJobPost, setCurrentJobPost] = useState<JobPostType>(
    initialCurrentJobPost
  );

  const [openAIPopUp, setOpenAIPopUp] = useState(false);

  const [competenciesReceived, setCompetenciesReceived] = useState(false);

  const [aiAvatar, setAiAvatar] = useState('');

  const [aiTitle, setAiTitle] = useState('');

  const [aiMessage, setAiMessage] = useState('');

  const [proceedNext, setProceedNext] = useState(false);

  const [currentCompetencies, setCurrentCompetencies] =
    useState<JobPostCompetenciesType>(initialCurrentCompetencies);
  const dispatch = useDispatch();
  const { newJobPost, jobData }: any = useSelector(
    (state: RootState) => state.job
  );
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const is_freelancer =
    currentJobPost.contract === 4 || existingJobPost.contract === 4;

  useEffect(() => {
    if (jobData.id && !isNew) updateInitialData(jobData);
  }, [jobData, isNew]);

  const updateInitialData = (data: any) => {
    setLoading(true);
    const { curJobPost, curCompetencies } = getInitialJobPostData(data, isNew);
    setCurrentJobPost(curJobPost);
    setCurrentCompetencies(curCompetencies);
    setTimeout(() => setLoading(false), 100);
  };

  const filterCompetenciesById = (targetIds: number[]) => {
    const allCompetencies = options.competencies?.flatMap(
      (group: any) => group.competencies
    );

    const filteredCompetencies = targetIds.reduce((acc, id) => {
      const matchedNames = allCompetencies.filter(
        (competency: Competency) => competency.id === id
      );

      return acc.concat(matchedNames);
    }, []);

    return filteredCompetencies;
  };

  const handleSetJobPostData = (field: string, value: any) => {
    let updatedValue = value;

    if (field === 'skills') {
      updatedValue = value.map((item: any) => {
        if (typeof item === 'string') {
          return { id: item, name: item };
        }
        return item;
      });
    }

    // This might need to be updated
    const updatedJobPost = {
      ...currentJobPost,
      [field]: updatedValue
    };

    if (field === 'salary' && updatedValue != null) {
      updatedJobPost.hourly_rate = null;
    } else if (field === 'hourly_rate' && updatedValue != null) {
      updatedJobPost.salary = null;
    }

    setCurrentJobPost(updatedJobPost);

    const fieldIsEmpty = fieldValidate(updatedValue);
    if (fieldIsEmpty) {
      setErrors({
        ...errors,
        [field]: true
      });
    } else if (!fieldIsEmpty && errors[field]) {
      const errorUpdate = { ...errors };
      delete errorUpdate[field];
      setErrors(errorUpdate);
    }
  };

  const errorHandler = (errors: any) => {
    setErrors(errors);
    if (Object.keys(errors).length) {
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.required, {
        variant: 'warning'
      });
    }
  };

  const setDataFromExistingPost = (data: any, isRemote: boolean) => {
    if (isRemote && !isNew) {
      setCurrentJobPost({ ...currentJobPost, remote_job: data });
    } else if (!isRemote && !isNew) {
      setCurrentJobPost(data);
      setExistingJobPost(data);
      updateInitialData(data);
      formValidate(data, errorHandler);
    } else if (!isRemote && isNew) {
      const result = {
        ...data,
        ...(existingJobPost.title && { title: existingJobPost.title }),
        ...(existingJobPost.status && { status: existingJobPost.status }),
        ...(existingJobPost.description && {
          description: existingJobPost.description
        }),
        ...(existingJobPost.contract && {
          contract: existingJobPost.contract
        }),
        ...(existingJobPost.remote_job && {
          remote_job: existingJobPost.remote_job
        }),
        ...(is_freelancer
          ? { hourly_rate: data.hourly_rate, salary: null }
          : { salary: data.salary, hourly_rate: null })
      };
      setCurrentJobPost(result);
      setExistingJobPost(result);
      updateInitialData(result);
      formValidate(result, errorHandler);
    } else {
      const result = {
        ...existingJobPost,
        ...data,
        id: existingJobPost.id || null,
        remote_job: data,
        ...(is_freelancer
          ? { hourly_rate: data.hourly_rate, salary: null }
          : { salary: data.salary, hourly_rate: null })
      };
      setCurrentJobPost(result);
      setExistingJobPost(result);
      updateInitialData(result);
      formValidate(result, errorHandler);
    }
  };

  const handleSetCompetencies = (label: string, id: number, text: string) => {
    const {
      competency_labels,
      competency_ids,
      competency_min_scores,
      descriptions
    } = currentCompetencies;
    const index = competency_labels.indexOf(label);

    if (index !== -1) {
      const newSelected = [...competency_labels];
      newSelected.splice(index, 1);

      setCurrentCompetencies({
        competency_labels: newSelected,
        competency_ids: [
          ...competency_ids.slice(0, index),
          ...competency_ids.slice(index + 1)
        ],
        competency_min_scores: [
          ...competency_min_scores.slice(0, index),
          ...competency_min_scores.slice(index + 1)
        ],
        descriptions: [
          ...descriptions.slice(0, index),
          ...descriptions.slice(index + 1)
        ]
      });
    } else if (competency_labels.length < MAX_COMPETENCY_SELECTED_LENGTH) {
      setCurrentCompetencies({
        competency_labels: [...competency_labels, label],
        competency_ids: [...competency_ids, id],
        competency_min_scores: [
          ...competency_min_scores,
          MIN_COMPETENCY_SLIDER_RANGE
        ],
        descriptions: [...descriptions, text]
      });
    }
  };

  const handleSetCompetenciesScore = (i: number, values: number[] | number) => {
    const { competency_min_scores } = currentCompetencies;
    const value: number = typeof values === 'number' ? values : values[0];

    const newMinRanges = [...competency_min_scores];
    newMinRanges[i] = value;

    setCurrentCompetencies({
      ...currentCompetencies,
      competency_min_scores: newMinRanges
    });
  };

  const handleSubmit = async () => {
    const { competency_ids, competency_min_scores } = currentCompetencies;

    const competencies = competency_ids.map((el: number, i: number) => ({
      competency: el,
      min_score: competency_min_scores[i]
    }));

    const data = {
      ...currentJobPost,
      culture_profile: currentJobPost?.culture_profile?.id,
      studies: currentJobPost.studies.map((study: any) => study?.id || study),
      industries: currentJobPost.industries.map(
        (industry: any) => industry?.id || industry
      ),
      skills: currentJobPost.skills.map((el: any) => el.id),
      competencies,
      customer: currentJobPost?.customer?.id,
      remote_job: currentJobPost?.remote_job?.id?.toString() || null
    };

    if (isNew) {
      data.user = userId;
      delete data.id;
      dispatch(postJob(data));
      setEdit(false);
    } else {
      const id = jobPostId;
      await dispatch(patchJobPost({ id, data }));
      await dispatch(fetchJob(id));
      setEdit(false);
      navigate(`${PATH_DASHBOARD.jobPosts.getJobPostUrl(id)}`);
    }
  };

  useEffect(() => {
    if (newJobPost && newJobPost.id)
      navigate(`${PATH_DASHBOARD.jobPosts.getJobPostUrl(newJobPost.id)}`);
  }, [newJobPost]);

  const getCompetenciesFromAI = async () => {
    setAiAvatar('/static/img/illustrations/lightbulb.svg');
    setAiTitle(t('THE MAGIC IS WORKING...'));
    setAiMessage(t('Hold on while we are suggesting the competencies for you based on the job description'));
    setOpenAIPopUp(true);
    setCompetenciesReceived(true);

    try {
      const res = await postDataByUrl(
        '/api/openai/extract_competencies/',
        {
          title: currentJobPost.title,
          description: currentJobPost.description
        },
        null,
        false,
        10000
      );

      if (res.competencies && res.competencies.length) {
        const list: Competency[] = filterCompetenciesById(res.competencies);

        const newCompetencies = {
          competency_labels: list.map((item) => item.name),
          competency_ids: list.map((item) => item.id),
          competency_min_scores: new Array(list.length).fill(
            MIN_COMPETENCY_SLIDER_RANGE
          ),
          descriptions: list.map((item) => item.description || '')
        };

        setCurrentCompetencies(newCompetencies);
        setOpenAIPopUp(false);
        setAiAvatar('');
        setAiTitle('');
        setAiMessage('');
      } else {
        enqueueSnackbar(t('NORA is not able to suggest any competency at the moment, please go ahead and select your desired competencies manually.'), {
            variant: 'error'
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkForCompetencies = async () => {
    await getCompetenciesFromAI();
  };

  useEffect(() => {
    if (activeStep === 1) {
      setProceedNext(false);
      setCompetenciesReceived(false);
    }
  }, [activeStep]);

  const generateAIJobDescription = async () => {
    setAiMessage(
      t('NORA is generating a description for you, please be patient.')
    );
    setOpenAIPopUp(true);

    const filterTypeByValue = (
      type: {
        value: number;
        label: string;
      }[],
      value: number
    ) => type.find((type) => type.value === value)?.label || '';

    const requestBody = {
      company: company.name || '',
      title: currentJobPost.title || '',
      locations:
        currentJobPost.locations.map((location) => location.display_name) || '',
      industries:
        currentJobPost.industries.map(
          (industry) => industry?.name || industry
        ) || '',
      studies:
        currentJobPost.studies.map((study) => study?.name || study) || '',
      degree: filterTypeByValue(DEGREE_TYPES, currentJobPost.degree),
      experience: filterTypeByValue(YEAR_CHOICES, currentJobPost.work_exp),
      contract: filterTypeByValue(CONTRACT_TYPES, currentJobPost.contract),
      start_date: currentJobPost.available || '',
      salary: is_freelancer
        ? `${filterTypeByValue(
            FREELANCE_HOURLY_RATES,
            currentJobPost.hourly_rate || 0
          )} ${company.currency}/hr`
        : `${filterTypeByValue(SALARY_CHOICES, currentJobPost.salary || 0)} ${
            company.currency
          }`
    };

    try {
      const res = await postDataByUrl(
        '/api/openai/generate_description/',
        requestBody,
        null,
        false,
        10000
      );
      if (res) {
        setCurrentJobPost({
          ...currentJobPost,
          description: res.description
        });
      }
    } catch (error: any) {
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.error, {
        variant: 'error'
      });
      console.error(error);
    }
    setOpenAIPopUp(false);
    setAiMessage('');
  };

  const generateJobDescription = async () => {
    await generateAIJobDescription();
  };

  const firstFormFields = {
    studies: currentJobPost.studies,
    title: currentJobPost.title,
    contract: currentJobPost.contract,
    degree: currentJobPost.degree,
    work_exp: currentJobPost.work_exp,
    ...(is_freelancer
      ? { hourly_rate: currentJobPost.hourly_rate }
      : { salary: currentJobPost.salary }),
    locations: currentJobPost.locations,
    industries: currentJobPost.industries,
    available: currentJobPost.available,
    drivers_license: currentJobPost.drivers_license,
    assessment_required: currentJobPost.assessment_required,
    culture_profile: currentJobPost.culture_profile,
    ...(currentJobPost.remote_job && { remote_job: currentJobPost.remote_job })
  };

  const secondFormFields = {
    description: currentJobPost.description,
    skills: currentJobPost.skills,
    musts: currentJobPost.musts,
    perks: currentJobPost.perks,
    status: currentJobPost.status,
    anonymous: currentJobPost.anonymous
  };

  const firstFields = [
    {
      type: 'select',
      value: null,
      label: t('You can select one of the existing job posts to auto-fill the form and save yourself time'),
      onChange: (e: any, el: any) => setDataFromExistingPost(el, false),
      options: options.allJobPosts,
      helperText: t('You can select one of existing job-posts to auto fill form')
    },
    {
      isRemoteJob: true,
      type: 'select',
      options: options.remote_job,
      value: currentJobPost.remote_job || null,
      label: t('If you have the same job in your ATS system, please input the Job'),
      onChange: (e: any, el: any) => setDataFromExistingPost(el, true)
    },
    ...useFormFieldsByType(
      'job-post-first-step',
      currentJobPost,
      handleSetJobPostData,
      options,
      errors,
      '',
      '',
      company.currency,
      company.is_agency,
      undefined,
      is_freelancer
    )
  ];

  const secondFields = useFormFieldsByType(
    'job-post-second-step',
    currentJobPost,
    handleSetJobPostData,
    options,
    errors
  );

  const { competency_labels, competency_min_scores, descriptions } =
    currentCompetencies;

  const steps = [
    {
      label: t('Please fill out all necessary data'),
      nextEnable: true,
      subtitle: 'STEP 1',
      nextValidation: () =>
        formValidate(
          firstFormFields,
          errorHandler,
          makeNotRequired(firstFormFields, 'available')
        ),
      startSrc: '',
      activeSrc: '/static/img/jobs/icons/necessary-active.svg',
      endSrc: '/static/img/jobs/icons/necessary-end.svg'
    },
    {
      label: t('Select job requirements'),
      nextEnable: true,
      subtitle: 'STEP 2',
      nextValidation: () =>
        formValidate(
          secondFormFields,
          errorHandler,
          makeNotRequired(secondFormFields, 'skills')
        ),

      startSrc: '/static/img/jobs/icons/requirements-start.svg',
      activeSrc: '/static/img/jobs/icons/requirements-active.svg',
      endSrc: '/static/img/jobs/icons/requirements-end.svg'
    },
    {
      label: t('Select competencies'),
      subtitle: 'STEP 3',
      nextEnable:
        competency_labels.length >= MIN_COMPETENCY_SELECTED_LENGTH &&
        competency_labels.length <= MAX_COMPETENCY_SELECTED_LENGTH,

      startSrc: '/static/img/jobs/icons/competencies-start.svg',
      activeSrc: '/static/img/jobs/icons/competencies-active.svg',
      endSrc: '/static/img/jobs/icons/competencies-end.svg'
    },
    {
      label: t('Select competencies score'),
      subtitle: 'STEP 4',
      nextEnable: true,
      startSrc: '/static/img/jobs/icons/score-start.svg',
      activeSrc: '/static/img/jobs/icons/score-active.svg',
      endSrc: ''
    }
  ];

  const formKey = `form-${isNew}-${is_freelancer}`;

  const getActiveStepComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <DynamicForm
            key={formKey}
            inputValues={firstFields}
            title={t('Necessary Data')}
            isNew={isNew}
            styling={true}
          />
        );
      case 1:
        return (
          <DynamicForm inputValues={secondFields} type="job-post-second-step" />
        );
      case 2:
        return (
          <Box>
            <Typography>
              {t('We advise you to select no more than 10 competencies And you should only go for the once that really matter to achieve success in the work he candidate needs to execute The average is 7 competencies per matching profile But depending on your results you can always add or delete competencies This way you can fine-tune your ideal profile')}
            </Typography>
            <CompetenciesSelect
              competencies={options.competencies}
              selectedList={competency_labels}
              onSelect={handleSetCompetencies}
            />
          </Box>
        );
      case 3:
        return (
          <CultureProfileSecondStep
            selectedOptionsLabels={competency_labels}
            minRanges={competency_min_scores}
            setRanges={handleSetCompetenciesScore}
            descriptions={descriptions}
          />
        );
      default:
        return 'No such step';
    }
  };

  const title = `${isNew ? t('Add') : t('Edit')} ${t('Job Post')}`;

  if (isLoading) return <LoadingScreen />;
  return (
    <Page title={title}>
      <PageContainer
        style={{
          padding: 0
        }}
      >
        <DashboardNavbar title={title} />
        <AIModal
          open={openAIPopUp}
          onClose={() => setOpenAIPopUp(false)}
          customAvatar={aiAvatar}
          customTitle={aiTitle}
          customMessage={aiMessage}
          textStyle={{ fontSize: '16px', fontWeight: 400 }}
        />
        <Grid mt={2}>
          <StepperComponent
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            onFinish={handleSubmit}
            onBack={() => setEdit(false)}
            steps={steps}
            editJobPost={!isNew}
            isFreeAccount={isFreeAccount}
            checkForCompetencies={() => checkForCompetencies()}
            generateJobDescription={() => generateJobDescription()}
            competenciesReceived={competenciesReceived}
            onNextStep={proceedNext}
          >
            {getActiveStepComponent()}
          </StepperComponent>
        </Grid>
      </PageContainer>
    </Page>
  );
};

const InfoJobPost = ({
  isNew,
  // isCandidate,
  setEdit,
  userId,
  jobPostId
}: any) => {
  const dispatch = useDispatch();
  const { jobData }: any = useSelector((state: RootState) => state.job);
  const navigate = useNavigate();

  const { mainInfo } = useSelector(
    (state: RootState) => state.candidate.candidate
  );
  const { count, isLoading } = useSelector(
    (state: RootState) => state.campaignsReducer
  );
  const { result: competencies }: any = useSelector(
    (state: RootState) => state.candidate.assessmentResult
  );
  const { t } = useLocales();

  const {
    user,
    isAdmin,
    isCandidate,
    isEmployee,
    isRecruiterPlus,
    isRecruiter,
    isHiringManager
  } = useAuth();

  const isCandidateOrEmployee = isCandidate || isEmployee;

  const isOwner = jobData.user === userId;

  const ownedByBoundingRecruiter = jobData.user === user.recruiter;

  const canTakeAction =
    isAdmin ||
    isRecruiterPlus ||
    (isRecruiter && isOwner) ||
    (isHiringManager && ownedByBoundingRecruiter);

  const canAccessCampaigns =
    isAdmin || isRecruiterPlus || isRecruiter || isHiringManager;

  const canApplyForJob =
    (isCandidateOrEmployee && !jobData.assessment_required) ||
    (isCandidateOrEmployee && competencies);

  const cantApplyForJobButCanTakeAssessment =
    isCandidateOrEmployee && jobData.assessment_required && !competencies;

  const canEditJob = !isNew && canTakeAction;

  const cultureCompetenciesDescription = useMemo(
    () =>
      jobData?.culture_profile?.competencies?.map(
        (el: any) => el?.competency?.description
      ),
    [jobData?.culture_profile?.competencies?.length]
  );

  const candidateCompetencies = mainInfo?.competencies || [];

  const matchCompared = getComparedData(
    candidateCompetencies,
    jobData?.competencies
  );

  const cultureCompared = getComparedData(
    candidateCompetencies,
    jobData?.culture_profile?.competencies
  );

  const [culture_labels, culture_min_scores] = useMemo(() => {
    if (!jobData?.culture_profile) {
      return [[], []];
    }

    const labels =
      jobData.culture_profile.competencies?.map(
        (el: any) => el?.competency?.name
      ) ?? [];
    const minScores =
      jobData.culture_profile.competencies?.map((el: any) => el?.min_score) ??
      [];

    return [labels, minScores];
  }, [jobData?.culture_profile]);

  const opt: any = useSelector((state: RootState) => state.fixturesReducer);
  const options = { ...opt };
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );

  if (company.currency == 'INR') {
    options.salaries = SALARY_CHOICES_INR;
  }
  const fields = getCardValuesByType(
    'job-post',
    jobData,
    options,
    company.currency
  );

  const startCampaign = () => {
    navigate(`${PATH_DASHBOARD.campaigns.startCampaign}`);
    setCurrentJobPostId(jobData.id?.toString(), jobData.title);
  };

  const getCampaign = async () => {
    canAccessCampaigns &&
      userId &&
      jobData?.id &&
      dispatch(getCampaignsThunk(userId, undefined, undefined, jobData?.id));
  };

  useEffect(() => {
    getCampaign();
  }, [canAccessCampaigns, userId, jobData]);

  const openOrderedCampaigns = (jobPostId: number) => {
    navigate(
      `${PATH_DASHBOARD.campaigns.getCampaignsOverviewForJobPost(jobPostId)}`
    );
  };

  const { BACK } = getButtons();

  const [currentJobPost, setCurrentJobPost] = useState<JobPostType>(
    initialCurrentJobPost
  );

  const handleSetJobPostData = async (value: any) => {
    if (value) {
      const opption = STATUSES[value - 1];
      const current = {
        ...jobData,
        status: opption.label
      };
      setCurrentJobPost(current);
      await updateJobPost(jobData?.id, {
        status: opption.label
      });
    } else {
      setCurrentJobPost(currentJobPost);
    }
  };

  const { STATUSES } = getTranslatedList();

  const getStatusOption = (job: any) =>
    STATUSES.find((elem) => elem.label === job?.status);

  useEffect(() => {
    if (jobData?.id) {
      setCurrentJobPost(jobData);
    }
  }, [jobData]);

  const convertHtmlToMailtoFormat = (html: string) =>
    htmlToText(html, {
      wordwrap: 130,
      preserveNewlines: true
    });

  const InviteCandidate = (
    url: string,
    title: string,
    description: string
  ): void => {
    const plainTextDescription = convertHtmlToMailtoFormat(description);

    const subject = encodeURIComponent(title);
    const body = encodeURIComponent(
      `${plainTextDescription}\n\nCheck out this link: ${url}`
    );
    const mailtoLink = `mailto:?subject=${subject}&body=${body}`;

    window.location.href = mailtoLink;
  };

  return (
    <Page title={jobData.title}>
      <PageContainer
        style={{
          padding: 0
        }}
      >
        <DashboardNavbar title={jobData.title} />
        <Button
          onClick={() => navigate('/jobs/')}
          variant="text"
          style={{
            color: '#562C82',
            marginBottom: '20px'
          }}
        >
          <ArrowBackIosNew
            style={{
              fontSize: '18px',
              margin: '0 0 5px 0'
            }}
          />
          {BACK}
        </Button>
        <Card sx={{ p: 2 }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              borderBottom: '1px solid #e0e0e0',
              p: 2
            }}
          >
            {canTakeAction && (
              <Box>
                {count > 0 && !isLoading && (
                  <Button
                    onClick={() => openOrderedCampaigns(jobData.id)}
                    variant="outlined"
                    color="success"
                    sx={{ mr: 2 }}
                  >
                    {t('Ordered campaigns')}: {count}
                  </Button>
                )}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '10px'
                  }}
                >
                  <Button
                    onClick={startCampaign}
                    variant="contained"
                    style={{
                      background:
                        'linear-gradient(92.25deg, #9b5fda -2.57%, #562c82 113.38%)',
                      padding: '10px 45px',
                      borderRadius: '30px'
                    }}
                  >
                    {t('Start a new job marketing Campaign')}
                  </Button>
                </div>
              </Box>
            )}
            {canApplyForJob && (
              <ApplyJobButton
                jobPostId={jobData.id}
                onApply={() => {}}
                isApplied={!!jobData?.is_applied}
              />
            )}
            {cantApplyForJobButCanTakeAssessment && <TestAssessmentButton />}
            <Box />
            <Grid
              style={{
                marginLeft: 'auto',
                marginRight: '20px',
                display: 'flex'
              }}
            >
              {canTakeAction && (
                <OverviewSelect
                  width={180}
                  candidate={{ color: '' }}
                  options={STATUSES}
                  activeOption={getStatusOption(currentJobPost)?.value}
                  setOption={(value: any) => handleSetJobPostData(value)}
                  styleAutocomplete={{
                    borderRadius: '30px'
                  }}
                />
              )}
              {canEditJob && <EditButton onClick={setEdit} />}
            </Grid>
            <SharedButtons
              url={`${HOST_URL}${PATH_HOME.getJobUrl(jobData.id)}`}
              title={jobData.title}
              description={jobData.description}
            />
          </Box>
          <CardContent>
            {canTakeAction && (
              <Box sx={{ display: 'flex', justifyContent: 'end', mb: '12px' }}>
                <Button
                  onClick={() =>
                    InviteCandidate(
                      `${HOST_URL}${PATH_HOME.getJobUrl(jobData.id)}`,
                      jobData.title,
                      jobData.description
                    )
                  }
                  variant="contained"
                  style={{
                    background:
                      'linear-gradient(92.25deg, #9b5fda -2.57%, #562c82 113.38%)',
                    padding: '10px 45px',
                    borderRadius: '30px'
                  }}
                >
                  {t('Invite candidate for this job')}
                </Button>
              </Box>
            )}
            <Box
              color="GrayText"
              style={{
                whiteSpace: 'pre-wrap',
                fontWeight: 400,
                fontSize: '18px',
                color: '#131417'
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: jobData.description }} />
            </Box>
          </CardContent>
          <CardContent>
            <Grid container>
              {fields.map((el: any, i: number) => (
                <Grid item xs={4} key={i} sx={{ mt: 2 }}>
                  <TypographyTitleJobPost variant="h6">
                    {t(el.label)}
                  </TypographyTitleJobPost>
                  <TypographyDescriptionJobPost
                    variant="body1"
                    color="GrayText"
                  >
                    {t(el.value)}
                  </TypographyDescriptionJobPost>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
        <CardContent
          style={{
            padding: '20px 0'
          }}
        >
          <Grid
            container
            spacing={1}
            style={{
              justifyContent: 'space-between'
            }}
          >
            <Grid item xs={12} md={6} sx={{}}>
              {jobData?.score && (
                <ScoreField
                  type="talent"
                  score={jobData?.score}
                  variant="h5"
                  align="center"
                />
              )}
              <OpenJobPostCard
                item={jobData}
                onDelete={() => {}}
                onEdit={() => {}}
                editable={false}
                compared={matchCompared}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {jobData?.culture_score && (
                <ScoreField
                  type="culture"
                  score={jobData?.culture_score}
                  variant="h5"
                  align="center"
                />
              )}
              <OpenJobPostCard
                item={{
                  ...jobData,
                  competency_labels: culture_labels,
                  culture_score: culture_min_scores,
                  descriptions: cultureCompetenciesDescription,
                  competencies: jobData?.culture_profile?.competencies,
                  title: jobData?.culture_profile?.name
                }}
                onDelete={() => {}}
                onEdit={() => {}}
                editable={false}
                compared={cultureCompared}
                nocustomer={true}
              />
            </Grid>
          </Grid>
        </CardContent>
      </PageContainer>
    </Page>
  );
};

const JobPost = () => {
  const dispatch = useDispatch();
  const [isEdit, setEdit] = useState(false);
  const { loading, allJobPosts, competencies, remote_job }: any = useSelector(
    (state: RootState) => state.job
  );
  const { user, isAdmin, isCandidate, isEmployee, isRecruiterPlus } = useAuth();
  let { id } = useParams<any>();

  const location = useLocation();

  if (location.hash === '#new') {
    id = 'new';
  }

  const isNew = id === 'new';
  const isCandidateOrEmployee = isCandidate || isEmployee;
  const userId = user.id;

  const handleEdit = () => {
    setEdit(!isEdit);
  };

  const getInitialData = () => {
    if (isNew) {
      dispatch(resetNewJobPost());
    }
    dispatch(getCandidateInfo(user?.candidate_id));
    if (!isNew && id) {
      dispatch(fetchJob(id));
    } else setEdit(true);

    if (!isCandidateOrEmployee) {
      dispatch(fetchAllJobPosts());
    }
  };

  useEffect(() => {
    if (isAdmin || isRecruiterPlus) {
      dispatch(fetchRemoteJobs());
    }
  }, [isAdmin, isRecruiterPlus]);

  const optionsStore: any = useSelector(
    (state: RootState) => state.fixturesReducer
  );

  const optionsObj = {
    ...optionsStore,
    allJobPosts,
    competencies,
    remote_job
  };

  useEffect(() => {
    getInitialData();
    dispatch(resetNewJobPost());
  }, [id, isNew, isCandidateOrEmployee, userId, user]);

  if (loading && !isNew) return <LoadingScreen />;
  return isNew || isEdit ? (
    <EditJobPost
      options={optionsObj}
      isNew={isNew}
      jobPostId={id}
      userId={userId}
      setEdit={handleEdit}
    />
  ) : (
    <InfoJobPost
      isNew={isNew}
      jobPostId={id}
      userId={userId}
      setEdit={handleEdit}
    />
  );
};

export default JobPost;
